import { ArrowRightIcon } from "@heroicons/react/20/solid"
import type { PropsWithChildren } from "react"
import { isValidUrl } from "common/lib/isValidUrl"
import { CallToActionStyle } from "common/types/graphql"
import { Heading } from "common/components/Heading"
import clsx from "classnames"
import { Button } from "common/components/Button"

export interface CallToActionProps {
  href: string
  title?: string
  text: string
  style: CallToActionStyle
}

export const CallToAction = ({
  href,
  title,
  text,
  style,
}: PropsWithChildren<CallToActionProps>) => {
  const sanitizedHref = isValidUrl(href) ? href : undefined

  if (title) {
    return (
      <div
        className={clsx("my-6 flex flex-col items-center gap-6 px-6 py-8 text-center", {
          "bg-gray-50 text-black": style === CallToActionStyle.Default,
          "bg-red-500 text-white": style === CallToActionStyle.Donate,
          "bg-indigo-600 text-white": style === CallToActionStyle.Newsletter,
        })}
      >
        <Heading level={4}>{title}</Heading>
        <Button
          size="large"
          onDark={style === CallToActionStyle.Default ? false : true}
          primary
          href={href}
        >
          {text}
        </Button>
      </div>
    )
  }
  return (
    <div className="my-4">
      <a href={sanitizedHref} className="inline-flex gap-3 text-sm font-bold">
        {text} <ArrowRightIcon className="inline size-5 flex-none" />
      </a>
    </div>
  )
}

export type CallToActionComponent = typeof CallToAction
