export const isValidUrl = (urlString?: string) => {
  if (typeof urlString !== "string") {
    return false
  }

  try {
    const url = new URL(urlString)
    if (url.protocol === "javascript:") {
      return false
    }
    return true
  } catch (error) {}
  return false
}
